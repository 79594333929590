@font-face {
  font-family: 'gentium_book_basicitalic';
  src: url('fonts/genbkbasi-webfont.woff2') format('woff2'),
       url('fonts/genbkbasi-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;

}


@font-face {
  font-family: 'gentium_book_basicregular';
  src: url('fonts/genbkbasr-webfont.woff2') format('woff2'),
       url('fonts/genbkbasr-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


.App {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar, .footer {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  padding: 2rem;
}

.barcontainer {
  background-color: #000;
  margin: 0.25rem;
  cursor: pointer;
}

.bar {
  width: 2.25rem;
  height: 0.3rem;
  margin: 0.4rem 0;
  background-color: #fff;
}

.navbar-link {
  color: #fff;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
  text-decoration: none;
  display: none;
}

.navbar-link:hover {
  border-bottom: .25rem solid #fff;
}

@media screen and (min-width: 40em) {
  .navbar-link {
    display: inline-flex;
  }
  .barcontainer, .bar {
    display: none;
  }
}

.sidenav {
  height: 100%;  
  width: 25%;  
  position: fixed; 
  z-index: 1; 
  top: 0; 
  left: 0; 
  background-color:#232323; 
  transition: .5s ease; 
  overflow-x: hidden; 
  padding-top: 20px; 
  display: flex; 
  flex-direction: column; 
  align-items: center;
}

.close {
  background-color: #232323;
  color: #fff;
  font-size: 2rem;
  border: none;
  margin-bottom: 1.5rem;
}

.close:hover {
  background-color: #000;
  color: #7B68EE;
}

.sidelink {
  color: #fff;
  text-decoration: none;
  font-size: 2rem;
  padding-bottom: 1.5rem;
}

.sidelink:hover {
  color: #7B68EE;
}

.radial-bg, .dark-bg {
  width: 100%;
  height: auto;
  color: #fff;
}

.radial-bg {
  background-image: radial-gradient(circle, #6A5ACD, #000);
}
.dark-bg {
  background-color: #232323;
}

hr {
  width: 80%;
  margin: 5rem auto;
  border: 3px ridge #ccc;
}

.flex-textcolumn, .text-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro, .skills, .flex-textcolumn {
  margin-top: 2rem;
  text-align: center;
}

.intro, .skills > *, .flex-textcolumn > *, .icons-inline > * {
  margin-top: auto;
  margin-bottom: 0;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.intro > *, .skills > * {
  margin-inline: clamp(0.75rem, 1rem, 2rem);
}

.flex-textcolumn p {
  max-width: 50ch;
}

.text-layer1 {
  font-size: max(1rem + 3vw, 52px);
  font-weight: bold;
}

.text-layer2 {
  font-size: max(1rem + 2vw, 32px);
}

.text-layer3 {
  font-size: max(1rem + 1vw, 20px);
}

.text-layer4 {
  font-size: max(0.75rem + 1vw, 16px);
}

.shadow {
  padding: 0.5rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  font-family: monospace
}

blockquote {
  font-family: 'gentium_book_basicitalic';
  font-size: max(1.125rem + 1vw, 20px);
  margin-inline: auto;
  max-width: 25em;
}

blockquote + p {
  font-family: 'gentium_book_basicregular';
  font-size: max(1rem + 1vw, 20px);
}

@media screen and (max-width: 40em) {
blockquote, .flex-textcolumn.text-layer2, .flex-textcolumn.text-layer3 {
  margin-inline: 1rem;
}
}

.flex-textrow {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
}

img {
  width: 50%;
  height: auto;
  border: none;
  border-radius: 50%;
}

button {
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  font-size: 1.25rem;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #fff;
  color: #000;
}

.icons-inline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
}

.icons-inline > * {
  color: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 1rem;
}

.icons-inline a:visited {
  color: #ccc;
  cursor: pointer;
}

.icons-inline a:hover {
  color: #fff;
  cursor: pointer;
}

.icons-inline a:active {
  color: #ccc;
  cursor: pointer;
}

.icons-inline p {
  padding-right: 0.75rem;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row input[type=text], [type=email], textarea{
  width: 75%;
  padding: 12px;
  margin: 1rem;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  resize: vertical;
}

.row label {
  padding: 12px 12px 12px 0;
  width: 25%;
  color: #fff;
}

.row input[type=submit] {
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
  padding: 12px 20px;
  margin: 1rem 2rem 2rem;
  border: 2px solid #1a33f0;
  border-radius: 4px;
  cursor: pointer;
  float: left;
}

.row input[type=submit]:hover {
  background-color: #1a33f0;
}

.col-25 {
  width: 25%;
}

.col-75 {
  width: 75%;
}

.logo-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15vw;
  min-width: 160px;
  height: auto;
}

.logo-box img {
  width: 67%;
  height: auto;
}

.logo-box, .outline-box {
  color: #ccc;
  background-color: #483D8B;
  font-size: large;
  padding-block: 1rem;
  padding-inline: 2rem;
  margin-block: 1rem;
  border: solid 2px #ccc;
  border-radius: 4px;
}

.outline-box {
  width: min(30ch, 80vw);
}

.outline-box:hover {
  background-color: #6A5ACD;
  color: #fff;
  border-color: #fff;
  cursor: pointer;
}

a, a:active, a:visited {
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .col-25, .col-75 {
    width: 100%;
  }

  .row input[type=submit] {
    background-color: #1a33f0;
    width: 100%;
    margin: 1rem auto;
  }
}

.single-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.single-column > * {
  color: #fff;
  margin: 1.5rem 2rem;
}